@import "./variables";

// General

.bg-alt {
  background-color: $body-bg-alt;
}

.btn-padded {
  padding-left: 60px;
  padding-right: 60px;
}

// Page Setup
.page {
  .sidebar {
    ul.nav {
      padding: 0;
      margin: 0;
      list-style: none;

      li.nav-item {
        padding: 0;
        position: relative;
        white-space: nowrap;
        display: block;

        &.group-header {
          padding: 0 1.5rem !important;
          margin: 20px 0 10px 0;
          text-transform: uppercase;
          font-weight: bold;
        }
      }

      li.nav-item {
        border-top: 1px solid rgba(0, 0, 0, 0);
        border-bottom: 1px solid rgba(0, 0, 0, 0);

        .nav-link {
          padding: 0 1.5rem !important;
          line-height: 2.2rem;
          color: #11223f;
        }

        &.active {
          border-top: 1px solid rgba(0, 0, 0, 0.04);
          border-bottom: 1px solid rgba(0, 0, 0, 0.04);
          background-color: rgba(44, 108, 243, 0.1);

          .nav-link {
            font-weight: bold;
          }
        }
      }
    }
  }

  .page-header {
    .title {
      margin-top: 5px;
      font-size: 24px;
    }
  }
}

// Page Section

h1.sec-title {
  font-size: 1.25rem;
}

h4.sec-title {
  font-size: 1rem;
}

// Pagination

.pagination-cont {
  margin-top: 12px;
  margin-bottom: 8px;
}

// Table

table {
  $table-border: 1px solid
    darken(
      $color: $body-bg-alt,
      $amount: 5%,
    ) !important;

  thead {
    background-color: $body-bg-alt;
    border: $table-border;
  }

  tr {
    border-left: $table-border;
    border-right: $table-border;

    &:last-child {
      border-bottom: $table-border;
    }
  }

  th {
    font-weight: normal !important;
  }

  td {
    border-top: $table-border;
  }

  .sub-text {
    font-size: 0.75rem;
    color: $gray-600;
    display: block;
  }
}

.table-header {
  margin: 4px 4px 10px 0;

  display: flex !important;

  justify-content: space-between !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
}

.cursor-pointer {
  cursor: pointer;
}

// Spinner
.spinner-center {
  right: 50%;
  position: fixed;
  display: block;
}

// leaflet-map
.leaflet-container {
  width: 100%;
  height: 100%;
}

// Branding
.navbar-brand {
  height: 40px;
  width: 100%;
  background: url("../assets/imgs/branding/logo-primary.png");
  background-repeat: no-repeat;
  background-size: auto 40px;
}

.overflow-x {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  scrollbar-width: thin;
}

// common excel-upload-page css
.site-upload {
  .page-title {
    height: 24px;
    width: auto;
    color: #11223f;
    font-size: 26px;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 400;
  }

  .card {
    padding: 40px;

    &__step {
      font-size: 12px;
      height: 20px;
      letter-spacing: 0;
      line-height: 20px;
      margin-bottom: 5px;
    }

    &__title {
      font-size: 20px;
      height: 40px;
      letter-spacing: 0;
      line-height: 40px;
      margin-bottom: 0;
      margin-top: 0;
    }

    &__message {
      font-size: 16px;
      height: 22px;
      letter-spacing: 0;
      line-height: 22px;
      margin-top: 0;
    }
  }

  .btn-action {
    min-width: 220px;
    position: relative;
  }
}

.add-button,
.added-button,
.remove-button {
  padding: 2px !important;
  border-radius: 6px !important;
  width: 85px;
  background: #f3f7ff;
}

// for larger progress bar
.progress-bar-lg {
  height: 22px;
}

// ------------------------------------------------
// CSS for Spitted Screen (2-section)
// Variables
$left-sec-width: 50%;

.map-layout-left {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  height: 100%;
  width: $left-sec-width;
  border-right: 1px solid #ccc;
  overflow-y: auto;

  padding: 10px 16px;

  .page-header {
    padding: 2px 0px;
  }
}

.map-layout-right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: $left-sec-width;

  height: 100%;
  box-shadow: inset 0 0 2px 2px #ccc;
}

// ---------------------------------------------------
// Opacity CSS
.opacity {
  &-25 {
    opacity: 0.25;
  }
  &-50 {
    opacity: 0.5;
  }
  &-75 {
    opacity: 0.75;
  }
  &-100 {
    opacity: 1;
  }
}

// rounded
.rounded-xl {
  border-radius: 1.3rem !important;
}

// Image CSS
.img-cont {
  padding: 4px;
  border: 1px solid $gray-300;
}
.img-thumbnail-sm {
  height: 65px;
  width: 65px;
}
.img-thumbnail-md {
  height: 150px;
  width: 150px;
}
.img-thumbnail-lg {
  height: 220px;
  max-width: 250px;
}

// text-truncate
// for 1-line : bootstrap-class :: "text-truncate"
// for truncate after 2-lines
.text-truncate-2 {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
// for truncate after 3-lines
.text-truncate-3 {
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// monitorig media log modal
#media-log-image-form {
  .media-log-image {
    height: 650px;
    width: 100%;
  }
}

.download-button {
  position: absolute;
  top: 50;
}

// font size
.fs-6 {
  font-size: 1rem;
}

// row gap
.row-gap-6 {
  row-gap: 24px;
}

// max height
.max-h-400 {
  max-height: 400px;
}

.max-h-200 {
  max-height: 200px;
}

// Text-Blink CSS
.text-blink {
  cursor: pointer;
  animation: blink 1.5s linear infinite;
  background: linear-gradient(to right, purple, red);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}
// help videos
.help-videos {
  row-gap: 40px;

  .card {
    min-height: 300px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .video-content {
      height: 300px;
      width: inherit;
      border: none;
    }
  }
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}
